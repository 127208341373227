var zino_resize = function (event) {
  if (event.origin !== "http://www.oreogroup.com") {
    return;
  }
  var zino_iframe = document.getElementById('zino_iframe');
  if (zino_iframe) {
    zino_iframe.style.height = event.data + "px";
  }
};
if (window.addEventListener) {
  window.addEventListener("message", zino_resize, false);
} else if (window.attachEvent) {
  window.attachEvent("onmessage", zino_resize);
}